(function () {
    'use strict';

    angular.module('common').factory('appDispatcher', appDispatcher);

    appDispatcher.$inject = ['$rootScope', '$q'];

    function appDispatcher($rootScope, $q) {
        return {
            emit: emit,
            broadcast: broadcast,
            on: on,
            when: when
        };

        function broadcast(eventName, payload) {
            $rootScope.$broadcast(eventName, payload);
            return payload;
        }

        function emit(eventName) {
            return _.partial(broadcast, eventName);
        }

        function on() {
            $rootScope.$on.apply($rootScope, arguments);
        }

        function when(eventName) {
            var defer = $q.defer();
            on(eventName, defer.resolve);
            return defer.promise;
        }
    }

}());
